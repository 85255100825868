export const carouselsData: { id: number; url: string; link: string }[] = [
  {
    id: 1,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/Bitis_WEB.jpg',
    link: '',
  },
  {
    id: 2,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/ROUTINE_WEB.jpg',
    link: '',
  },
  {
    id: 3,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-2.jpeg',
    link: '',
  },
  {
    id: 4,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-5.jpeg',
    link: '',
  },
  {
    id: 5,
    url: 'https://storage.googleapis.com/fundiin-asset/homepage/assets/images/banner/200524-6.jpeg',
    link: '',
  },
];
