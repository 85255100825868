import CarouselBase from 'src/components/Carousels/CarouselBase';

import { carouselsData } from './const';

const Carousel1 = () => {
  return (
    <div className="carousel1">
      <CarouselBase infinite autoplaySpeed={1000} autoplay swipe>
        {carouselsData.map(c => (
          <div key={c.id}>
            <img
              src={c.url}
              alt={c.alt}
              loading="eager"
              className="cover-img"
            />
          </div>
        ))}
      </CarouselBase>
    </div>
  );
};

export default Carousel1;
